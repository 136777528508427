/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as o } from "../../core/accessorSupport/decorators/subclass.js";
import { read as t, write as i } from "../effects/jsonUtils.js";
const s = {
    type: ["average", "color-burn", "color-dodge", "color", "darken", "destination-atop", "destination-in", "destination-out", "destination-over", "difference", "exclusion", "hard-light", "hue", "invert", "lighten", "lighter", "luminosity", "minus", "multiply", "normal", "overlay", "plus", "reflect", "saturation", "screen", "soft-light", "source-atop", "source-in", "source-out", "vivid-light", "xor"],
    nonNullable: !0,
    json: {
      read: !1,
      write: !1,
      origins: {
        "web-map": {
          read: !0,
          write: !0
        },
        "portal-item": {
          read: !0,
          write: !0
        }
      }
    }
  },
  n = {
    read: {
      reader: t
    },
    write: {
      allowNull: !0,
      writer: i
    }
  },
  a = {
    json: {
      read: !1,
      write: !1,
      origins: {
        "web-map": n,
        "portal-item": n
      }
    }
  },
  l = t => {
    let i = class extends t {
      constructor() {
        super(...arguments), this.blendMode = "normal", this.effect = null;
      }
    };
    return e([r(s)], i.prototype, "blendMode", void 0), e([r(a)], i.prototype, "effect", void 0), i = e([o("esri.layers.mixins.BlendLayer")], i), i;
  };
export { l as BlendLayer, s as blendModeProperty, a as effectProperty };